import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "national-order-database-nod"
    }}>{`National Order Database (NOD)`}</h1>
    <h2 {...{
      "id": "-notice-sftp-service-shutdown"
    }}>{`📢 Notice: SFTP Service Shutdown`}</h2>
    <p>{`We would like to inform you that the SFTP solution we are hosting at Entur is planned to be replaced by a new REST API,
which is integrated into the IOS application. For details on how to use the REST API, please contact the NOD Ticketing Team.`}</p>
    <p>{`We plan to shut down the SFTP server during September 2025.`}</p>
    <h2 {...{
      "id": "about-this-document"
    }}>{`About this document`}</h2>
    <p>{`The purpose of this document is to provide an overview of the functionality that the National Order Database platform enables (hereafter called NOD). The document also provides a review of the general functionality of NOD.
The document also serves as a general guide to relevant parts of `}<a parentName="p" {...{
        "href": "https://www.jernbanedirektoratet.no/dokumenter/?_search=v821&_attachment_categories=handboker"
      }}>{`Handbook V821`}</a>{`.`}</p>
    <h2 {...{
      "id": "about-national-order-database"
    }}>{`About National Order Database`}</h2>
    <p>{`NOD was developed by Interoperabilitetstjenester A / S on behalf of the Norwegian Public Roads Administration, with the intention of simplifying electronic ticketing and interoperability between various public transport operators.
Some of the benefits of NOD:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The security solution is on the server side. This means that "insecure" clients such as mobile phones or home PCs can be used as vending machines for collecting tickets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The business logic is on the server side. This means that the business rules can be changed without the clients having to be updated.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Distribution is separated from the sales channel. This allows anyone in principle to sell tickets.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The solution is always online. This means that sold tickets are immediately available nationally.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The platform is modular. Operators can develop their own "plugins" with their own business rules, also for non-interoperable products.`}</p>
      </li>
    </ul>
    <p>{`NOD is an implementation of the technical specifications found in `}<a parentName="p" {...{
        "href": "https://www.jernbanedirektoratet.no/content/uploads/2023/11/hb-v821-part-22-national-order-database.pdf"
      }}>{`Handbook V821.22`}</a>{` for Online distribution of sold tickets. This implementation is a platform that can be used by PTOs as a "cloud-based" service in the "Software as a Service" category.
NOD has two primary interfaces: one back system for PTOs and one internet interface for NOD Clients.
`}<img alt="NOD model" src={require("./nod-model.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      